<template>
  <v-responsive
    class="v-img bg-gray/20 overflow-hidden relative"
    :class="[rounded ? 'rounded-circle' : 'rounded-lg']"
    :aria-label="normalisedSrc.alt"
    :role="normalisedSrc.alt ? 'img' : undefined"
    :aspect-ratio="normalisedSrc.aspectRatio"
    :style="{ width: w }"
  >
    <template v-if="normalisedSrc.src">
      <v-img
        v-if="blurBg"
        :src="lazySrc || normalisedSrc.src"
        class="image-bg"
        width="100%"
        height="100%"
        cover
      />
      <NuxtImg
        v-if="normalisedSrc.src"
        class="v-img__img image-content__img"
        :class="containClasses"
        :placeholder="lazySrc"
        :src="normalisedSrc.src"
        :fit="fit"
        :alt="normalisedSrc.alt"
      />
    </template>
  </v-responsive>
</template>

<script lang="ts">
import { isString } from "lodash";
import { defineComponent } from "vue";
import type { PublicMedia } from "~~/modules/sales/src";

export const isPublicMedia = (
  src: PublicMedia | string | undefined
): src is PublicMedia => {
  return !!src && !isString(src);
};

export default defineComponent({
  name: "ImageField",
  props: {
    aspectRatio: { type: [String, Number] },
    src: { type: Object as PropType<PublicMedia | string> },
    srcset: { type: String },
    lazySrc: { type: String },
    size: { type: [String, Number], default: "40" },
    width: { type: [String, Number] },
    height: { type: [String, Number] },
    alt: { type: String },
    fit: { type: String as PropType<"cover" | "contain">, default: "contain" },
    rounded: { type: Boolean, default: false },
    blurBg: { type: Boolean, default: false },
  },
  setup(props) {
    const normalisedSrc = computed(() => {
      return isPublicMedia(props.src)
        ? {
            // src: props.src.fqfn,
            src: props.src.src,
            srcset: props.srcset || props.src.srcset,
            lazySrc: props.lazySrc || props.src.thumb,
            aspectRatio: Number(
              props.aspectRatio || props.src.aspectRatio || 0
            ),
            width: props.width || props.src.width || props.size,
            height: props.height || props.src.height,
            alt: props.alt || props.src.alt,
          }
        : {
            src: props.src,
            srcset: props.srcset,
            lazySrc: props.lazySrc,
            aspectRatio: Number(props.aspectRatio || 0),
            width: props.width || props.size,
            height: props.height,
            alt: props.alt,
          };
    });

    const containClasses = computed(() => ({
      "v-img__img--cover": props.fit === "cover",
      "v-img__img--contain": props.fit !== "cover",
    }));

    const w = computed(() => convertToUnit(normalisedSrc.value.width));

    return {
      normalisedSrc,
      containClasses,
      w,
    };
  },
});
</script>

<style lang="scss" scoped>
.image-bg {
  @apply absolute inset-0 z-[-10];
  filter: blur(7px);
}
</style>
